<template>
  <div v-if="isDetail" class="p-wrap">
    <div v-if="!isFullScreen" class="tabs">
      <el-tabs v-model="active">
        <el-tab-pane label="申报表" name="declare" :disabled="declare.declareStatus =='300'"/>
        <el-tab-pane label="底稿" name="manuscript"/>
        <el-tab-pane label="审批流程" name="approval"/>
      </el-tabs>
      <el-button class="back" type="default" @click="handleBack">返回</el-button>
    </div>
    <declare ref="declare" v-if="active == 'declare'" :declare-prop="declare" @change="handleChange"
             @toHistory="handleToHistory"></declare>
    <manuscript ref="manuscript" v-if="active == 'manuscript'" :declare-prop="declare" @change="handleChange"
                @toHistory="handleToHistory"></manuscript>
    <approval v-if="active == 'approval'" @change="handleChange" @toHistory="handleToHistory"></approval>
  </div>
  <router-view v-else></router-view>
</template>

<script>
import Declare from './components/Declare'
import Manuscript from './components/Manuscript'
import Approval from './components/Approval'

import {
  getDeclareByDraftTableId
} from '@/service/declare/index'

export default {
  name: 'DeclareDetail',
  components: { Declare, Manuscript, Approval },
  watch: {
    '$route.path': {
      handler () {
        this.isDetail = this.$route.path == '/declare/ent/tmpl/detail' ? true : false
      },
      immediate: true
    },
    active () {
      setTimeout(() => this.$refs[`${this.active}`].initXlsx(), 0)

    }
  },
  data () {
    return {
      id: btoa(this.$route.query.id),
      declare: {},
      isFullScreen: false,
      isDetail: true,
      active: this.$route.query.type
    }
  },
  created () {
    this.handleInitDeclare()
  },
  mounted () {
    this.$bus.$on('full-screen', type => this.isFullScreen = type)
  },
  methods: {
    // 返回主页
    handleBack () {
      this.$router.push('/declare/ent/tmpl/list')
    },
    async handleInitDeclare () {
      const { success, data } = await getDeclareByDraftTableId(this.id)
      if (success) this.declare = data
    },
    // 跳转底稿记录
    handleToHistory () {
      this.$router.push({
        path: '/declare/tmpl/detail/history',
        query: { declareTmplId: this.id }
      })
    },
    handleChange (val) {
      this.active = val
      // 提交成功切换申报表
      this.handleInitDeclare()

    }
  }
}
</script>

<style scoped lang="scss">
.p-wrap {
  position: relative;

  ::v-deep .el-tabs__item {
    margin-top: 7px;
    color: #999999;
    font-weight: 400;
    font-size: 16px;
  }

  ::v-deep .el-tabs__item.is-active {
    color: #3d94ff;
  }
}

.back {
  position: absolute;
  right: 24px;
  top: 8px;
}
</style>
